import React from "react"
import { graphql, Link } from "gatsby"
import he from "he"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const IndexEN = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const blogs = data.allDblog.edges
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "en-US",
      },
    ],
  }

  const bloglist = () => {
    return blogs.map((blog, k) => {
      console.log(blog)
      return (
        <div key={k} className="pb-2 text-xl">
          <Link to={`/en/blogs/${blog["node"]["slugEn"]}`}>
            {he.decode(blog["node"]["titleEn"])}
          </Link>
          {blog["node"]["tagsEn"] ? (
            <p className="text-base">{he.decode(blog["node"]["tagsEn"])}</p>
          ) : (
            <></>
          )}
          <p className="text-base">
            {new Date(blog["node"]["date"]).toLocaleDateString("en-US")}
          </p>
        </div>
      )
    })
  }

  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | Blog"
        description="Blog"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language="en"
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className=" mx-2 md:mx0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-1" />
            <div className="col-span-10">
              <h1>Blog</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8">
              {bloglist()}

              <div className="h-10" />
            </div>
          </div>
          <div className="h-10" />
          <div className="h-10" />
          <div className="max-w-full">
            <div
              className="col-span-12"
              style={{ margin: "0 auto", maxWidth: "500px" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default IndexEN

export const appSyncQuery = graphql`
  query {
    allDblog(
      filter: {
        published: { eq: true }
        contentType: { eq: "blog-netbistrot" }
      }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          slugEn
          titleEn
          tagsEn
          date
        }
      }
    }
  }
`
